<script setup>
import { CARD_ITEMS } from '@/components/homepage/HwFromSoloToSocial/constants.js';

const isCarouselVisible = ref(false);
const sectionRef = ref(null);

const items = reactive(CARD_ITEMS);

let animationInterval = null;
let observer = null;

onMounted(() => {
  observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      isCarouselVisible.value = true;
      runAnimation();
    }
    if (!entries[0].isIntersecting && animationInterval) {
      clearInterval(animationInterval);
      animationInterval = null;
    }
  });
  observer?.observe(sectionRef.value);
});

onBeforeUnmount(() => {
  observer?.unobserve(sectionRef?.value);
});

const filteredItems = computed(() => {
  return items.slice(0, 3);
});

const getMiddleItemTitle = computed(() => {
  return filteredItems.value[1].title;
});

onBeforeUnmount(() => {
  if (animationInterval)
    clearInterval(animationInterval);
});

function shiftAndPushItem() {
  const shifted = items.shift();

  setTimeout(() => {
    items.push(shifted);
  }, 400);
}

function runAnimation() {
  animationInterval = setInterval(() => {
    shiftAndPushItem();
  }, 3000);
}
</script>

<template>
  <div
    ref="sectionRef"
    class="card-carousel"
  >
    <TransitionGroup
      v-if="isCarouselVisible && items"
      tag="div"
      name="social-carousel"
      class="cards-container"
    >
      <HomepageHwFromSoloToSocialCommunityCard
        v-for="(item, i) in filteredItems"
        :key="item.title"
        :title="item.title"
        :name="item.name"
        :frame-url="item.frameUrl"
        :avatar-url="item.avatarUrl"
        :location="item.location"
        :time="item.time"
        :participants-count="item.participantsCount"
        :participants-avatar-urls="item.participantsAvatarUrls"
        :class="{ ['middle-item']: i === 1 }"
      />
    </TransitionGroup>
    <div class="snaps-container">
      <div
        v-for="item in filteredItems"
        :key="item.thumbnailUrl"
        class="thumbnail-group"
        :class="{ active: item.title === getMiddleItemTitle }"
      >
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="item.thumbnailUrl"
          :size="48"
        />
        <span class="thumbnail-text">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-carousel {
  height: wds-rem(480px);
  width: 100%;
  overflow-x: hidden;
  position: relative;
  margin-bottom: $wds-spacing-xl;
  display: flex;
  justify-content: center;

  @include tablet-large {
    height: wds-rem(580px);
  }

  @include desktop-large {
    position: absolute;
    height: wds-rem(600px);
    left: wds-rem(500px);
    width: calc(100% - 500px);
  }
}

.cards-container {
  display: flex;
  column-gap: $wds-spacing-s;
  align-items: center;
  height: wds-rem(335px);

  @include tablet-large {
    height: wds-rem(412px);
  }

  & > div {
    opacity: 0.5;
    filter: blur(6px);
    transition: all 0.4s linear;
  }

  & > div:nth-child(4) {
    animation: card-appear 0.4s linear;

    @keyframes card-appear {
      from {
        opacity: 0;
        transform: translateX(25%);
      }

      to {
        opacity: 0.5;
        transform: translateX(0);
      }
    }
  }

  & > div.middle-item {
    opacity: 1;
    filter: blur(0);
    width: wds-rem(204px);
    height: wds-rem(335px);
    margin: 0 $wds-spacing-s;

    @include tablet-large {
      width: wds-rem(231px);
      height: wds-rem(412px);
    }
  }
}

.snaps-container {
  top: wds-rem(360px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: $wds-spacing-s;
  max-width: 100vw;
  height: wds-rem(102px);

  @include tablet-large {
    top: wds-rem(440px);
    column-gap: $wds-spacing-l;
  }

  & .thumbnail-group {
    width: wds-rem(48px);
    height: wds-rem(48px);

    & > div {
      position: relative;
    }

    &,
    & :deep(.avatar img) {
      width: wds-rem(48px);
      height: wds-rem(48px);
      transition:
        width 0.4s linear,
        height 0.4s linear;
    }

    &.active {
      width: wds-rem(72px);
      height: wds-rem(72px);

      & :deep(.avatar) {
        & img {
          width: wds-rem(72px);
          height: wds-rem(72px);
        }
      }
    }
  }

  & .thumbnail-text {
    @include body-3-reg;

    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
  }
}
</style>

<style lang="scss">
.social-carousel-move,
.social-carousel-enter-active,
.social-carousel-leave-active {
  transition: all 0.4s;
}

.social-carousel-leave-active {
  position: absolute !important;
  transform: translateX(0);
}

.social-carousel-enter {
  opacity: 0 !important;
}

.social-carousel-leave-to,
.social-carousel-leave-from {
  opacity: 0 !important;
  transform: translateX(-25%);
}
</style>
