<template>
  <section class="social-section-wrapper">
    <HomepageHwFromSoloToSocialCardsCarousel />
    <div class="text">
      <header
        class="text-header"
        v-html="$t('t_LINKUPSHEADER')"
      />
      <p class="text-body">
        {{ $t('t_LINKUPSBODY') }}
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.social-section-wrapper {
  padding: $wds-spacing-xxxl 0;

  @include desktop-large {
    height: wds-rem(660px);
    margin-bottom: 0;
    margin-left: calc((100% - 1600px) / 2);
    width: calc(100% - calc((100% - 1600px) / 2));
  }

  .text {
    display: flex;
    flex-direction: column;
    padding: 0 $wds-spacing-l;
    position: relative;
    max-width: wds-rem(800px);
    margin: 0 auto;

    @include tablet-large {
      padding: 0 calc(#{$wds-spacing-xxxl} + #{$wds-spacing-s});
    }

    @include desktop-large {
      padding: unset;
      margin: unset;
      margin-left: calc(#{$wds-spacing-xxxl} + #{$wds-spacing-xxxl});
      width: wds-rem(464px);
      padding-top: $wds-spacing-xl;
    }

    &-header {
      font-family: $font-family-poppins;
      font-size: wds-rem(40px);
      line-height: wds-rem(48px);
      letter-spacing: $wds-letter-spacing-lg-title-3;
      font-weight: 700;
      hyphens: auto;

      @include tablet-large {
        @include title-1-bld;

        font-weight: 700;
        margin-top: calc(#{$wds-spacing-s} + #{$wds-spacing-xs});
      }

      @include desktop-large {
        font-size: wds-rem(64px);
        line-height: wds-rem(64px);
        letter-spacing: $wds-letter-spacing-lg-title-3;
        margin-top: 0;
      }

      & :deep(span) {
        color: $wds-color-teal-light;
      }
    }

    &-body {
      padding: $wds-spacing-m 0;
    }

    :deep(.tag-text) {
      @include title-6-bld;

      display: block;
      width: fit-content;
      position: relative;
    }
  }
}
</style>
