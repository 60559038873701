<script setup>
import { WdsIcon } from '@wds/components';

const props = defineProps({
  frameUrl: {
    type: String,
    required: true,
  },
  participantsAvatarUrls: {
    type: Array,
    required: true,
  },
  participantsCount: {
    type: Number,
    required: true,
  },
  avatarUrl: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  location: {
    type: String,
    required: true,
  },
  time: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const isVideoUrl = computed(() => {
  return /(mp4)$/m.test(props.frameUrl);
});
</script>

<template>
  <div class="card-wrapper">
    <video
      v-if="isVideoUrl"
      autoplay
      loop
      muted
      playsinline
      title=""
      class="media"
    >
      <source
        :src="frameUrl"
        type="video/mp4"
      />
    </video>
    <img
      v-else
      :src="frameUrl"
      alt=""
      class="media"
    />
    <div class="content">
      <div class="content-topGroup">
        <div class="content-avatar">
          <HomepageHwSeeWhoIsGoingAvatar
            :img-url="avatarUrl"
            :size="32"
          />
        </div>
        <div class="content-name">
          {{ name }}
        </div>
      </div>
      <div class="content-bottomGroup">
        <h4 class="content-title">
          {{ title }}
        </h4>
        <div class="content-info">
          <span class="content-time"><WdsIcon
            asset="clock"
            color="white"
          />{{ time }}</span><span class="content-location"><WdsIcon
            asset="location-pin"
            color="white"
          />{{ location }}</span>
        </div>
        <div class="content-bottom">
          <div class="content-peopleCount">
            <div class="content-avatarsGroup">
              <HomepageHwSeeWhoIsGoingAvatar
                v-for="url in participantsAvatarUrls"
                :key="url"
                :img-url="url"
                :size="24"
              />
            </div>
            <div class="content-coming">
              {{ $t('t_SOLOTOSOCIALPARTICIPANTS', { NUMOFPARTICIPANTS: props.participantsCount }) }}
            </div>
          </div>
          <div class="content-button">
            Join
            <WdsIcon
              asset="arrow-right"
              color="white"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-wrapper {
  height: wds-rem(316px);
  width: wds-rem(184px);
  position: relative;

  @include tablet-large {
    height: wds-rem(388px);
    width: wds-rem(227px);
  }

  .media {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: $wds-border-radius-l;
  }

  .content {
    height: 100%;
    width: 100%;
    padding: calc(#{$wds-spacing-m} - #{$wds-spacing-xs}) $wds-spacing-s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: $wds-z-index-toast;
    color: $wds-color-white;
    border-radius: $wds-border-radius-l;
    background: linear-gradient(180deg, rgb(80 28 17 / 0%) 60%, #2c120c 100%);

    &-topGroup {
      display: flex;
      align-items: center;
    }

    &-bottonGroup {
      display: flex;
      flex-direction: column;
    }

    &-avatar {
      & > div {
        position: relative;
        margin-right: $wds-spacing-s;
      }
    }

    &-name {
      color: $wds-color-white;
      font-weight: 600;
      font-size: wds-rem(8px);
      transform: translateY(-2px);
    }

    &-title {
      font-style: $font-family-inter;
      font-size: wds-rem(18px);
      line-height: wds-rem(24px);
      letter-spacing: -0.1px;
      margin-bottom: $wds-spacing-s;
      font-weight: $wds-font-weight-regular;
    }

    &-button {
      background-color: $wds-color-purple;
      padding: $wds-spacing-xs calc(#{$wds-spacing-s} + #{$wds-spacing-xs});
      font-weight: 600;
      font-size: wds-rem(16px);
      line-height: wds-rem(21px);
      letter-spacing: $wds-letter-spacing-title-2;
      border-radius: wds-rem(12px);
      display: flex;
      align-items: center;

      & .icon-container {
        height: wds-rem(12px);
        width: wds-rem(12px);
        margin-left: $wds-spacing-xs;
      }
    }

    &-info {
      font-weight: 600;
      font-size: wds-rem(8px);
      line-height: wds-rem(11px);
      display: flex;
      margin-bottom: $wds-spacing-xs;

      & span {
        display: flex;
        align-items: center;

        .icon-container {
          height: wds-rem(10px);
          width: wds-rem(10px);
          margin-right: $wds-spacing-xs;
          transform: translateY(-3px);
        }
      }
    }

    &-time {
      margin-right: $wds-spacing-m;
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
    }

    &-peopleCount {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: wds-rem(8px);
      line-height: wds-rem(11px);
    }

    &-avatarsGroup {
      position: relative;
      width: wds-rem(64px);
      transform: translateY(-5px);

      & > * {
        & :deep(img:first-child) {
          border: solid 1px $wds-color-black !important;
        }

        & :deep(img:last-child) {
          display: none;
        }

        &::before {
          display: none;
        }

        &:nth-child(1) {
          z-index: $wds-z-index-toast;
        }

        &:nth-child(2) {
          z-index: $wds-z-index-dropdown;
          left: wds-rem(18px);
        }

        &:nth-child(3) {
          z-index: $wds-z-index-overlay;
          left: wds-rem(36px);
        }
      }
    }

    &-coming {
      align-self: flex-end;
    }
  }
}
</style>
